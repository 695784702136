.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  margin-left: -8px;
  position: absolute;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  box-sizing: content-box;
  position: absolute;
  border: 8px solid transparent;
  height: 0;
  width: 1px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  content: "";
  z-index: -1;
  border-width: 8px;
  left: -8px;
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  top: 0;
  margin-top: -8px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-top: none;
  border-bottom-color: #f0f0f0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  bottom: 0;
  margin-bottom: -8px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  border-bottom: none;
  border-top-color: #fff;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  bottom: -1px;
  border-top-color: #aeaeae;
}

.react-datepicker-wrapper {
  display: inline-block;
}

.react-datepicker {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px;
}

.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0;
}

.react-datepicker--time-only .react-datepicker__time {
  border-radius: 0.3rem;
}

.react-datepicker--time-only .react-datepicker__time-box {
  border-radius: 0.3rem;
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker-popper {
  z-index: 1;
}

.react-datepicker-popper[data-placement^="bottom"] {
  margin-top: 10px;
}

.react-datepicker-popper[data-placement^="top"] {
  margin-bottom: 10px;
}

.react-datepicker-popper[data-placement^="right"] {
  margin-left: 8px;
}

.react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
  left: auto;
  right: 42px;
}

.react-datepicker-popper[data-placement^="left"] {
  margin-right: 8px;
}

.react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
  left: 42px;
  right: auto;
}

.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  padding-top: 8px;
  position: relative;
}

.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px;
}

.react-datepicker__current-month,
.react-datepicker-time__header {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: 0.944rem;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  background: none;
  line-height: 1.7rem;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 10px;
  width: 0;
  padding: 0;
  border: 0.45rem solid transparent;
  z-index: 1;
  height: 10px;
  width: 10px;
  text-indent: -999em;
  overflow: hidden;
}

.react-datepicker__navigation--previous {
  left: 10px;
  border-right-color: #ccc;
}

.react-datepicker__navigation--previous:hover {
  border-right-color: #b3b3b3;
}

.react-datepicker__navigation--previous--disabled, .react-datepicker__navigation--previous--disabled:hover {
  border-right-color: #e6e6e6;
  cursor: default;
}

.react-datepicker__navigation--next {
  right: 10px;
  border-left-color: #ccc;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 80px;
}

.react-datepicker__navigation--next:hover {
  border-left-color: #b3b3b3;
}

.react-datepicker__navigation--next--disabled, .react-datepicker__navigation--next--disabled:hover {
  border-left-color: #e6e6e6;
  cursor: default;
}

.react-datepicker__navigation--years {
  position: relative;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker__navigation--years-previous {
  top: 4px;
  border-top-color: #ccc;
}

.react-datepicker__navigation--years-previous:hover {
  border-top-color: #b3b3b3;
}

.react-datepicker__navigation--years-upcoming {
  top: -4px;
  border-bottom-color: #ccc;
}

.react-datepicker__navigation--years-upcoming:hover {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__month {
  margin: 0.4rem;
  text-align: center;
}

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #aeaeae;
  width: 70px;
}

.react-datepicker__time-container--with-today-button {
  display: inline;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  position: absolute;
  right: -72px;
  top: 0;
}

.react-datepicker__time-container .react-datepicker__time {
  position: relative;
  background: white;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 70px;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  list-style: none;
  margin: 0;
  height: calc(195px + (1.7rem / 2));
  overflow-y: scroll;
  padding-right: 30px;
  width: 100%;
  box-sizing: content-box;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  padding: 5px 10px;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #216ba5;
  color: white;
  font-weight: bold;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #216ba5;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #ccc;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__week-number {
  color: #ccc;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__day {
  cursor: pointer;
}

.react-datepicker__day:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}

.react-datepicker__day--today {
  font-weight: bold;
}

.react-datepicker__day--highlighted {
  border-radius: 0.3rem;
  background-color: #3dcc4a;
  color: #fff;
}

.react-datepicker__day--highlighted:hover {
  background-color: #32be3f;
}

.react-datepicker__day--highlighted-custom-1 {
  color: magenta;
}

.react-datepicker__day--highlighted-custom-2 {
  color: green;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #2a87d0;
  color: #fff;
}

.react-datepicker__day--keyboard-selected:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
  background-color: rgba(33, 107, 165, 0.5);
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range) {
  background-color: #f0f0f0;
  color: #000;
}

.react-datepicker__day--disabled {
  cursor: default;
  color: #ccc;
}

.react-datepicker__day--disabled:hover {
  background-color: transparent;
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 0.3rem;
}

.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
  cursor: pointer;
}

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  border-top-color: #ccc;
  float: right;
  margin-left: 20px;
  top: 8px;
  position: relative;
  border-width: 0.45rem;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #f0f0f0;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid #aeaeae;
}

.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}

.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: #ccc;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
}

.react-datepicker__close-icon {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: 0;
  padding: 0;
  vertical-align: middle;
  position: absolute;
  height: 16px;
  width: 16px;
  top: 25%;
  right: 7px;
}

.react-datepicker__close-icon::after {
  background-color: #216ba5;
  border-radius: 50%;
  bottom: 0;
  box-sizing: border-box;
  color: #fff;
  content: "\D7";
  cursor: pointer;
  font-size: 12px;
  height: 16px;
  width: 16px;
  line-height: 1;
  margin: -8px auto 0;
  padding: 2px;
  position: absolute;
  right: 0px;
  text-align: center;
}

.react-datepicker__today-button {
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647;
}

.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}

@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
  }
}

.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem;
}

.react-datepicker__portal .react-datepicker__navigation {
  border: 0.81rem solid transparent;
}

.react-datepicker__portal .react-datepicker__navigation--previous {
  border-right-color: #ccc;
}

.react-datepicker__portal .react-datepicker__navigation--previous:hover {
  border-right-color: #b3b3b3;
}

.react-datepicker__portal .react-datepicker__navigation--previous--disabled, .react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover {
  border-right-color: #e6e6e6;
  cursor: default;
}

.react-datepicker__portal .react-datepicker__navigation--next {
  border-left-color: #ccc;
}

.react-datepicker__portal .react-datepicker__navigation--next:hover {
  border-left-color: #b3b3b3;
}

.react-datepicker__portal .react-datepicker__navigation--next--disabled, .react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {
  border-left-color: #e6e6e6;
  cursor: default;
}

html,body {
	min-height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #CCCCCC url('/background.jpg') center center no-repeat;
  background-size: cover;
  font-size: 20px;
}
p {
	margin: 0 0 20px 0;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
* {
    box-sizing: border-box;
	cursor: default;
	cursor: url('/cursor.png') 12 12, auto;
}

/* general stuff */
.clearfix::after {
    content: "";
    clear: both;
    display: table;
}
.close {
    position: absolute;
    top: 3px;
    right: 3px;
    width: 16px;
    height: 16px;
    opacity: .2;
}
.close:hover {
    opacity: .4;
}
.close:before {
    content: '\1F5D9';
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 14px;
    line-height: 14px;
}
.intro-text {
	font-size: 80%;
	font-style: italic;
	margin: 10px 0 5px 0;
}

/* blocks */
/* header */
header#masthead {
	position: relative;
	width: calc(100% - 20px);
	margin: 10px calc(10px + 2%);
    padding: 5px 0 10px 0;
    height: 54px;
}
header#masthead img.App-logo {
	height: 100%;
	width: auto;
}

/* main content */
#main-content-wrapper {
	position: relative;
	width: calc(100% - 20px);
	margin: 10px auto;
	min-height: 0;
	transition: min-height 300ms ease-in;
}
#main-content-wrapper .funcs {
	float: left;
	width: 24%;
	max-width: 200px;
	padding: 0 2% 20px 2%;
	/* transition: max-width 750ms linear; */
}
#main-content-wrapper .funcs.active {
	float: right;
}
#main-content-wrapper .funcs.in-active {
	max-width: 0%;
}
#main-content-wrapper .funcs > img {
	width: 100%;
	height: auto;
}

/* text content */
#textroot {
	position: relative;
}
#textroot div.theContents {
	display: block;
	clear: both;
	max-height: 1px;
	overflow: hidden;
	width: 100%;
	margin: 0 auto;
    color: #4c4c4c;
    background-color: transparent;
    padding: 10px;
    box-sizing: border-box;
}
#textroot.active div.theContents {
	max-height: 5000px;
    background-color: #f5f6f7;
	transition: max-height 3000ms ease-in;
}
#textroot h1.contentHeader {
    line-height: 0.7em;
    margin-left: 10px;
    color: #f5f6f7;
    font-size: 40px;
    text-transform: uppercase;
}
#textroot a {
	color: #ffce40;
	text-decoration: underline;
}
#textroot h1,
#textroot h2,
#textroot h3,
#textroot h4,
#textroot h5,
#textroot h6 {
	margin: 0;
}

ul#info-list {
    list-style: none;
    margin: 0;
    padding: 0;
}
ul#info-list > li {
    list-style: none;
    padding: 4px 8px;
	margin: 8px 0;
    box-shadow: 2px 2px 2px 0px rgba(100,100,100,.2);
}
ul#info-list > li.active {
	box-shadow: unset;
    background: #FFF; 
}
li.parent-list {
    list-style: none;
    padding: 4px 8px;
}
ul.child-list {
    list-style: none;
    margin: 0 0 0 10px;
    padding: 0;
	max-height: 0;
    opacity: 0;
	display: none;
	transition: max-height 200ms linear, opacity 200ms linear;
}
li.active ul.open.child-list {
	max-height: 1000px;
	opacity: 1;
	display: block;
}
ul.child-list > li {
    margin: 8px 6px;
    padding: 4px 8px;
    /* background: #FFF; */
    font-weight: normal;
	box-shadow: 2px 2px 2px 0px rgba(100,100,100,.2);
}
li.parent-list.active {
    font-weight: bold;
	box-shadow: unset;
}
li[rel="external"]{
	background: transparent url(/link.png) right 4px center no-repeat;
    padding-right: 22px!important;
}

/* forms */
form .alert {
    margin: 0 -10px 15px;
    background-color: #ff8400;
    color: #FFF;
    padding: 15px 15px 15px 50px;
    font-size: 18px;
    background: #ff8400 url(/icon-alert.png) 12px 16px no-repeat;
    background-size: 30px auto;
}
#textroot form .alert a {
    color: #FFF;
    font-weight: bold;
}
form .form-group {
	margin-bottom: 15px;
}
label {
	font-weight: bold;
    padding-bottom: 3px;
    display: block;
}
input[type="text"],
input[type="password"],
select,
textarea {
    width: 100%;
    padding: 6px 10px;
    line-height: 1.42857143;
    color: #555;
    height: 40px;
    border: 0;
    border-radius: 0;
    font-size: 20px;
    box-sizing: border-box;
    display: block;
}
select {
	
}
textarea {
	height: unset;
}
#snapPicture {
    width: 100%;
    padding: 6px 10px;
    line-height: 1.42857143;
    color: #4c4c4c;
    height: 40px;
    font-size: 20px;
    box-sizing: border-box;
    display: block;
	background-color: #FFF;
	text-align: center;
}
#snappedPicture {
	width: 100%;
	padding: 0;
	margin: 5px 0 0 0;
}
#snappedPicture img {
	width: 100%;
	height: auto;
}
label.hasHelp {
    background: transparent url(/icon-info-g.png) right 3px no-repeat;
    background-size: 20px auto;
    padding-right: 23px;
}
form .formInfo {
	color: #777777;
	font-size: 16px;
	font-style: italic;
	max-height: 0px;
	overflow: hidden;
}
form .formInfo.active {
	padding: 0 0 5px 0;
	max-height: 200px;
	transition: max-height 200ms linear;
}
#submitForm {
    width: 100%;
    padding: 6px 10px;
    line-height: 1.42857143;
    color: #FFFFFF;
    height: 40px;
    font-size: 20px;
    box-sizing: border-box;
    display: block;
	text-align: center;
	background-color: #8bd4e0;
	text-transform: uppercase;
}
/* form helper */
.choseSomething { display: none; }
.choseSomething.active { display: block; }
.fhReceiver {
	position: relative;
	font-weight: normal;
	line-height: 40px;
	background-color: #FFF;
	padding: 0 0 0 14px;
}
.fhReceiver:after {
	content: '\FE40';
    position: absolute;
    right: 3px;
    top: 0;
    font-weight: bold;
    padding-top: 5px;
    height: 35px;
}
form .formElementHidden {
	display: none; 
}
.form-helper {
	max-height: 0;
	overflow: hidden;
	background-color: #FFF;
	transition: max-height 400ms linear;
}
.form-helper.active {
	max-height: 500px;
}
.form-helper .inner {
	padding: 14px;
}
.form-helper .fhOption {
    display: inline-block;
    background-color: #8bd4e0;
    line-height: 140%;
    color: #FFF;
    padding: 4px 10px 8px 10px;
    margin: 0 5px 5px 0;
    border-radius: 10px;
    font-size: 80%;
}
/* some fixes for datepicker layout */
form .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list { padding: 0; }
form .react-datepicker-wrapper,
form .react-datepicker__input-container { width: 100%; }
form .react-datepicker__input-container input[type="text"] { outline: none; }

/* community */
.messages {
	
}
#newMessages {
	font-size: 80%;
	text-align: right;
	font-style: italic;
    margin-right: 7px;
}
#messagesPreview {
	
}
.messagePreview {
	position: relative;
	clear: both;
    margin: 5px 2px 8px 0;
    padding: 5px 5px 5px 0;
    background-color: rgb(255,255,255,0.8);
    border: 1px solid #eee;
    box-shadow: 2px 2px 2px 0px rgba(100,100,100,.1);
}
.messagePreview.closed {
	display: none;
}
.mpAvatar {
    width: 60px;
    float: left;
}
.mpAvatar img {
    border-radius: 50%;
    margin: 5px;
}
.mpDetails {
    float: left;
    width: calc(100% - 70px);
    margin-left: 10px;
}
.mpFrom {
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    width: 100%;
    border-bottom: 1px dotted #ccc;
    padding: 2px 0 2px 5px;
    margin: 0 0 2px 0;
}
.mpFrom .mpTime {
    font-size: 12px;
    font-weight: normal;
    display: inline-block;
    margin-left: 5px;
}
.mpMessage {
    font-size: 14px;
    padding: 0 5px 5px 5px;
}

/* message detail */
#msgRoot {
    position: absolute;
    top: 28px;
    left: 0;
    width: 100%;
    background-color: #f5f6f7;
	max-height: 0;
    z-index: 2;
	transition: max-height 500ms linear;
	overflow: hidden;
}
#msgRoot.active {
    padding: 10px;
	max-height: 800px;
	display: block;
}
#msgRoot .close {
    top: 4px;
    right: 7px;
}
#msgRoot .close:before {
    font-size: 17px;
    line-height: 17px;
}
.msgHeader {
    border-bottom: 1px solid #90949c;
    padding: 0 0 5px 0;
    margin: 0 0 10px 0;
}
.msgHAvatar {
    float: left;
    padding: 0 10px 0 0;
}
.msgHAvatar img {
    float: left;
}
.msgHDetail {
    float: left;
    width: calc(100% - 60px);
    display: table;
}
.msgHName {
    display: table-cell;
    vertical-align: bottom;
    height: 50px;
    font-weight: bold;
}
.msgBlock {
    margin-bottom: 100px;
}
.msgRight {
	clear: both;
    font-size: 18px;
    border-radius: 10px;
    padding: 10px;
    background-color: #fdef08;
    max-width: 75%;
	float: right;
	margin-bottom: 10px;
}
.msgLeft {
	clear: both;
    font-size: 18px;
    border-radius: 10px;
    padding: 10px;
    background-color: #8bd4e0;
    max-width: 75%;
	margin-bottom: 10px;
}
.msgRight .msgTime,
.msgLeft .msgTime {
    font-size: 14px;
    font-weight: bold;
	/*
    border-bottom: 1px dashed #666;
    padding-bottom: 3px;
    margin-bottom: 3px; */
}
.msgLeft .msgContent {
	
}
.msgReply {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100px;
    width: 100%;
	padding: 0;
    background-color: #FFF;
}
.msgReply textarea {
    height: 100px;
    width: calc(100% - 20px);
	margin: 0 auto;
	outline: none;
}

/* events */
.eventPreviews {
	
}
.eventPreview {
    position: relative;
    clear: both;
    margin: 5px 2px 8px 0;
    padding: 5px 5px 5px 0;
    background-color: rgb(255,255,255,0.8);
    border: 1px solid #eee;
    box-shadow: 2px 2px 2px 0px rgba(100,100,100,.1);
}
.eventPreview.neighborhood {
    background-color: rgb(253,239,8, 1);
}
.eventPreview.closed {
	display: none;
}
.epDetails {
	
}
.epTitle {
    font-size: 16px;
    font-weight: bold;
    width: 100%;
    padding: 2px 20px 0px 8px;
    margin: 0 5px 2px 0px;
}
.epLocation {
    width: 100%;
    font-size: 14px;
    padding: 0px 5px 4px 8px;
    margin: 0 0px 5px 0px;
    border-bottom: 1px dotted #ccc;
}
.epDates {
	display: inline-block;
	font-size: 90%;
	margin-left: 5px;
}
.epDescription {
    font-size: 16px;
    padding: 0 5px 5px 8px;
}
.epAttending {
	font-size: 14px;
	font-weight: bold;
}
.epFrom {
	
}
.epTill {
	
}
/* event detail */
.eventHeader {
	
}
.eventHDetail {
	padding: 0 0 10px 0;
	margin: 0 0 10px 0;
	border-bottom: 1px solid #90949c;
}
.eventHTitle {
	font-weight: bold;
}
.eventHLocation {
	font-size: 90%;
}
.eventHDates {
	display: inline-block;
	margin-left: 5px;
}
.eventBlock {
	
}
.eventAttending {
	
}
.eventAAvatars {
    margin: 10px 0 0 12px;
}
.eventAAvatar {
    float: left;
    margin-left: -12px;
}
.eventAAvatar img {
    border-radius: 50%;
}
.eventAAvatarsMore {
	font-size: 90%;
}
.eventAttend {
    width: 100%;
    padding: 6px 10px;
	margin: 10px 0 20px 0;
    line-height: 1.42857143;
    color: #FFFFFF;
    height: 40px;
    font-size: 20px;
    box-sizing: border-box;
    display: block;
	text-align: center;
	background-color: #8bd4e0;
	text-transform: uppercase;
}
.eventAttend.attending {
	color: #4c4c4c;
	background-color: #fdef08;
}

/* traffic */
#trafficContainer {
	position: relative;
	width: 100%;
	height: 500px;
	overflow: hidden;
}
#trafficMap {
    position: absolute;
	width: 3356px;
	height: 1598px;
	left: -1000px;
	top: -1000px;
	z-index: 1;
}
#trafficData {
    position: absolute;
	width: 3356px;
	height: 1598px;
	left: -1000px;
	top: -1000px;
	z-index: 2;
}
#trafficDragger {
    position: absolute;
	width: 3356px;
	height: 1598px;
	left: -1000px;
	top: -1000px;
	z-index: 3;
}
#trafficContainer .exes {
    position: absolute;
    z-index: 4;
    background-color: #8bd4e0;
    border: 3px solid #067fcc;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: block;
    text-align: center;
    color: #067fcc;
    font-weight: bold;
    font-family: serif;
    opacity: .95;
	overflow: hidden;
	transition: max-width 200ms linear, max-height 200ms linear;
}
#trafficContainer .exes.active {
	width: unset;
	max-width: 300px;
	height: unset;
	max-height: 500px;
	background-color: rgb(139,212,224, 0.9);
	font-weight: normal;
	color: #4c4c4c;
	border-width: 1px;
	border-radius: 0;
	text-align: left;
    font-family: inherit;
    padding: 5px 10px;
    font-size: 17px;
    margin: 10px 0 0 10px;
}
#trafficContainer #thisEx2.exes.active {
    -webkit-transform: translate(0px,-81px);
            transform: translate(0px,-81px);
}
.accidentTitle {
	font-weight: bold;
	padding-bottom: 5px;
	margin-bottom: 5px;
	border-bottom: 1px solid #90949c;
}
.accidentResults {
    font-weight: bold;
    margin-top: 5px;
}

/* alerts */
#alerts {
	position: absolute;
	max-height: 0;
	overflow: hidden;
	top: 0;
	left: 0;
	width: 96%;
	background-color: rgb( 245, 246, 247, 0.8);
	color: #4c4c4c;
	z-index: 9999;
	margin: 6px 2% 0 2%;
	transition: max-height 300ms linear;
}
#alerts.active {
	max-height: 800px;
	padding: 10px;
}
#alerts .close {
	opacity: 0.8;
}
#alerts .close:before {
	font-size: 18px;
}
.weather-alert {
	max-width: 100%;
}
.waImage {
	max-width: 100%;
}
.waImage img {
	max-width: 100%;
	height: auto;
}
.waTime {
	position: absolute;
    top: 66%;
    left: 28%;
	width: 69%;
	text-align: center;
	font-size: 80%;
}
.waLink a {
	color: #ff8400;
}

/* loket */
.productFaqQuestion {
	position: relative;
	padding: 5px 10px;
	background-color: #FFF;
	border: 1px solid #90949c;
	margin: 0 0 5px 0;
}
.productFaqQuestion h2:after {
	position: absolute;
	right: 10px;
	top: 5px;
	content: '+';
	font-size: 30px;
	font-weight: bold;
}
.productFaqQuestion.active h2:after {
	content: '-';
	right: 14px;
}
.productFaqQuestion + .productFaqAnswers {
	max-height: 0;
	overflow: hidden;
	transition: max-height 200ms linear;
}
.productFaqQuestion + .productFaqAnswers.active {
	max-height: 1000px;
}
